<template lang="pug">
div
  labels(
    itemValue="ID"
    itemText="name"
    btnText="custom labels"
    :value="value"
    :items="labels"
    :hide-exclude="hideExclude"
    :disabled="disabled"
    @changed="onChanged"
    @changed:exclude="$emit('changed:exclude', $event)"
  )
    template(v-slot:activator="{on}")
      slot(name="activator" :on="on")
    template(v-slot:item-additional="{item}")
      create(@created="addNewLabel" :value="item" @updated="updateLabelInList" @deleted="removeLabelFromList")
        template(v-slot:activator="props")
          v-btn(icon x-small v-on="props.on" data-test="label-edit-btn")
            v-icon mdi-pencil
    <template v-slot>
      create(@created="addNewLabel" full-width)
    </template>
</template>

<script>
import {mapGetters, mapMutations} from "vuex"


export default {
  props: {
    value: {
      required: true
    },
    hideExclude: Boolean,
    disabled: Boolean
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      labels: 'labels/items'
    }),
  },

  methods: {
    ...mapMutations({
      _addLabel: 'labels/add',
      updateLabelInListByID: 'labels/update',
      removeLabelFromListByID: 'labels/remove'
    }),

    addNewLabel(label) {
      this._addLabel(label)
    },

    onChanged(labels) {
      this.$emit('changed', labels)
    },

    updateLabelInList(item) {
      this.updateLabelInListByID(item)
    },

    removeLabelFromList(ID) {
      this.removeLabelFromListByID(ID)
    }
  },

  components: {
    labels: () => import('./candidates/CandidatesLabels.vue'),
    create: () => import('@/app/admin/components/LabelCreate.vue')
  }
}
</script>

<style lang="scss" scoped>
.labels {
  background-color: #fff;

  &__content {
    padding: 10px;
  }

  &__item {
    width: calc(33% + 0px);
    margin: 0 -5px;
  }
}
</style>
